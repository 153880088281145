<!--
 * @Author: awenyjw
 * @Date: 2023-06-16 10:48:06
 * @LastEditors: awenyjw
 * @LastEditTime: 2023-06-19 16:59:00
 * @Description: 
 * 
-->
<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose" :append-to-body="true"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <div slot="title" class="header-title">
                <div>{{ msg.ntitle }}</div>
                <div>{{ msg.creatTime ? msg.creatTime.split('T')[0] : '' }}</div>
            </div>
            <div v-html="content" id="content"></div>
        </el-dialog>
    </div>
</template>
  
<script>
import templateTitle from '@/components/templateTitle.vue'
export default {
    data() {
        return {
            dialogVisible: false,
            content: '',
            msg: {}
        }
    },
    components: {
        templateTitle
    },
    mounted() {
        // this.changeStyle()
    },
    methods: {
        open(item) {
            console.log(item, '555555555')
            this.msg = item
            const regex = /(\/keditor)/g // 匹配/keditor
            const newStr = 'http://www.beijinghaice.com/keditor' // 需要添加的字符串
            this.content = item.nContent.replace(regex, newStr) // 进行替换操作
            this.msg = item
            console.log(this.content,this.msg,"=msg==");
            this.dialogVisible = true
        },
        btnClose() {
            this.content = ''
            this.dialogVisible = false
        },
        handleClose(done) {
            done()
        },
        changeStyle() {
            const spans = document.querySelectorAll('span')
            spans[1].style.color = 'blue'
        }
    }
}
</script>
  
<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';

.header-title {
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    flex-direction: column;

    &>div:nth-child(1) {
        line-height: 50px;
        text-align: center;
        color: #333;
        font-size: 29px;
        font-family: '新宋体';
        font-weight: bold;
        margin: 10px auto;
        padding: 0 40px;
    }

    &>div:nth-child(2) {
        font-size: 14px;
        font-family: PingFang SC-Semibold, PingFang SC;
        color: #212a33;
        margin-bottom: 5px;
    }
}

#content {
    line-height: 22px !important;
    font-size: 16px;
    max-height: 60vh;
    overflow-y: auto;
}

</style>
  